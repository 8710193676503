/**
 * It takes a number and returns an object with a className and a sign property
 * @param {number} change - number - the change in the coin value
 * @returns An object with a className and a sign.
 */
export const handleCoinChange = (change: number) => {
  if (change > 0) return { className: 'green', sign: '+' };
  if (change < 0) return { className: 'red', sign: '-' };
  return { className: 'white', sign: '' };
};
