const INSTRUMENT_DELIMITER = '_' as const;
const MARKET_DELIMITER = '/' as const;

const parse = (pair: string, delimiter: string) => {
  return pair?.toUpperCase().split(delimiter) || [];
};

/**
 * It takes a string like "btc_usd" and returns an object with the base, quote, and pair
 * @param {string} instrument - The instrument you want to trade.
 */
export const parseInstrument = (instrument: string) => {
  const result = { base: '', quote: '', market: '' };

  const [base, quote] = parse(instrument, INSTRUMENT_DELIMITER);
  result.base = base;
  result.quote = quote;
  result.market = base?.length && quote?.length ? `${base}${MARKET_DELIMITER}${quote}` : '';

  return result;
};

/**
 * It takes a string like `BTC/USD` and returns an object with the base and quote currencies and the
 * instrument
 * @param {string} market - The market you want to trade on.
 */
export const parseMarket = (market: string) => {
  const result = { base: '', quote: '', instrument: '' };

  const [base, quote] = parse(market, MARKET_DELIMITER);
  result.base = base;
  result.quote = quote;
  result.instrument = base?.length && quote?.length ? `${base}${INSTRUMENT_DELIMITER}${quote}` : '';

  return result;
};

export const parsePair = {
  byInstrument: parseInstrument,
  byMarket: parseMarket,
};

export const getMarketPair = (market: string) => {
  if (market.includes(MARKET_DELIMITER)) {
    return market;
  }
  if (market.includes(INSTRUMENT_DELIMITER)) {
    return parsePair.byInstrument(market).market;
  }
};

export const getInstrumentPair = (market: string) => {
  if (market.includes(MARKET_DELIMITER)) {
    return parsePair.byMarket(market).instrument;
  }
  if (market.includes(INSTRUMENT_DELIMITER)) {
    return market;
  }
};
