import { HubConnection, HubConnectionState, ISubscription } from '@microsoft/signalr';
import { selectedMarketInstrumentSelector, selectedMarketSelector, useMarketStore } from '@ping/stores/market.store';
import { useCallback, useEffect, useRef, useState } from 'react';

import { WSConnect } from './connection';
import { socketConnectivityChecker } from '@ping/helpers/socket-connectivity-checker.helper';
import * as gtm from '@ping/helpers/gtm.helper';
import * as Sentry from '@sentry/react';

export const useWebSocketMarket = <T>(
  url: string,
  streamName: 'Trades' | 'Book' | 'Trends',
  receiveDataCallBack: (data: T, interval: ReturnType<typeof setInterval>, callBackSetState) => void,
  streamAdditionalParam?: string,
  _onMessage?: (message: string) => void
) => {
  const [WSData, setWSData] = useState<T>(null);
  const connectionRef = useRef<HubConnection>(null);
  const tradeInterval = useRef<ReturnType<typeof setInterval>>(null);

  const selectedMarketReadable = useMarketStore(selectedMarketSelector);
  const selectedMarketInstrument = useMarketStore(selectedMarketInstrumentSelector);
  const unsubscribe = useRef<ISubscription<never>>(null);

  const handleWSMessage = useCallback(
    (connection: HubConnection, streamAdditionalParamArg: string) => {
      unsubscribe.current = connection.stream(streamName, streamAdditionalParamArg).subscribe({
        next: (data: T) => {
          /* make sure that we remove interval after receiving the response */
          if (receiveDataCallBack) {
            receiveDataCallBack(data, tradeInterval.current, setWSData);
          }
        },
        error: () => undefined,
        complete: () => {
          /* complete action */
        },
      });
    },
    [selectedMarketReadable]
  );

  const retryWS = (connection: HubConnection) => {
    if (connection?.state === HubConnectionState.Connected) {
      handleWSMessage(connection, streamAdditionalParam ? streamAdditionalParam : selectedMarketInstrument);
    } else {
      setTimeout(retryWS, 2000);
    }
  };

  useEffect(() => {
    const connection = WSConnect(url);
    connectionRef.current = connection;

    connection.onclose(() => {
      gtm.webSocketDisconnectedEvent('Market');
      socketConnectivityChecker('Market', false);
      Sentry.captureMessage(`[Websocket] ${streamName} Socket Disconnected`);
    });

    connection.onreconnected(async () => {
      await handleInitialConnection();
      if (connectionRef.current?.state) {
        socketConnectivityChecker('Market', connectionRef.current.state === HubConnectionState.Connected);
        Sentry.captureMessage(`[Websocket] ${streamName} Socket reconnected`);
      }
    });

    return () => {
      if (connection.state === HubConnectionState.Connected) {
        unsubscribe.current.dispose();
      }
    };
  }, []);

  const handleInitialConnection = async () => {
    const connection = WSConnect(url);
    connectionRef.current = connection;
    if (connection.state === HubConnectionState.Disconnected) {
      await connection.start();
    }
    retryWS(connection);
  };

  useEffect(() => {
    if (connectionRef.current) {
      /* initiate it to make it work not work without interval */
      /* we need to check it with backend team  */
      handleWSMessage(connectionRef.current, streamAdditionalParam ? streamAdditionalParam : selectedMarketInstrument);
      // tradeInterval.current = setInterval(() => {
      //   // unsubscribe.current.dispose();
      //   handleWSMessage(connectionRef.current, selectedMarketInstrument);
      // }, 1000);
    }
    return () => {
      clearInterval(tradeInterval.current);
      setWSData(null);
      unsubscribe.current.dispose();
    };
  }, [selectedMarketInstrument, connectionRef.current?.state === HubConnectionState.Connected]);

  useEffect(() => {
    if (connectionRef.current?.state) {
      socketConnectivityChecker('Market', connectionRef.current.state === HubConnectionState.Connected);
    }
  }, [connectionRef.current?.state]);

  return WSData;
};
