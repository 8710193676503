/**
 * "It takes a number or a string, and returns a number with a fixed number of decimal places."
 * @param {number | string} value - The value to be fixed.
 * @param [decimalCount=2] - The number of decimal places to round to.
 * @returns A function that takes two arguments, value and decimalCount.
 */
export const fixNumber = (value: number | string, decimalCount = 2) => {
  return parseFloat(Number(value).toFixed(decimalCount));
};

/**
 * It takes a number and returns a string with four decimal places
 */
export const fixAssetAmountFour = value => fixNumber(value, 4);
