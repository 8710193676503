import { Store } from '@ping/utils';
import { IMarketDataType } from '@ping/websockets';

// --------------------------------------------------------------------------------
// MARKET DATA STORE
// --------------------------------------------------------------------------------
export const useMarketDataStore = new Store<Map<string, IMarketDataType>>(new Map()).withDevTools().build();

// --------------------------------------------------------------------------------
// MARKET DATA STORE API
// --------------------------------------------------------------------------------
export const marketDataStore = {
  setMarketData: (marketData: Map<string, IMarketDataType>, replace = true) =>
    useMarketDataStore.setState(marketData, replace),
  getMarketData: () => useMarketDataStore.getState(),
  getSelectedMarketData: (selectedMarket: string) => useMarketDataStore.getState().get(selectedMarket),
};
