import {
  GetUserSettingResponseResponse,
  getUserSettingsGetQueryKey,
  useUserSettingsDelete,
  useUserSettingsGet,
  useUserSettingsUpdate,
} from '@ping/api';
import FavStar from '@ping/assets/Icon/favIcon.svg';
import { useIsUserLoggedIn } from '@ping/hooks';
import { queryClient } from '@ping/providers/ReactQueryProvider';
import clsx from 'clsx';

import style from './style.module.scss';

interface IFaveStarInputProps {
  className?: string;
  market?: string;
  onChange?: () => void;
}

export const FaveStarInput = ({ className = '', market, onChange }: IFaveStarInputProps) => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const { data: favoriteMarkets } = useUserSettingsGet({
    query: {
      enabled: isUserLoggedIn,
      staleTime: Infinity, // will be invalidated on change
      select: ({ data }) => new Set(data?.favoriteMarkets || []),
    },
  });

  const settingQueryKey = getUserSettingsGetQueryKey();

  const { mutate: updateSetting } = useUserSettingsUpdate({
    mutation: {
      onMutate: async ({ data }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(settingQueryKey);
        // Snapshot the previous value
        const previousTodos = queryClient.getQueryData(settingQueryKey);
        queryClient.setQueryData<GetUserSettingResponseResponse>(settingQueryKey, old => {
          const tempSetting = { ...old };
          (tempSetting.data.favoriteMarkets as string[]).push(data.value);
          return tempSetting;
        });
        // Return a context object with the snapshotted value
        return { previousTodos };
      },
      onError: (err, newTodo, context: any) => {
        queryClient.setQueryData(settingQueryKey, context.previousTodo);
      },
    },
  });
  const { mutate: deleteUserSetting } = useUserSettingsDelete({
    mutation: {
      onMutate: async ({ data }) => {
        await queryClient.cancelQueries(settingQueryKey);
        const previousTodos = queryClient.getQueryData(settingQueryKey);
        queryClient.setQueryData<GetUserSettingResponseResponse>(settingQueryKey, previous => {
          return {
            data: {
              ...previous.data,
              favoriteMarkets: previous.data.favoriteMarkets.filter(favMarket => favMarket !== data.value),
            },
          };
        });
        return { previousTodos };
      },
      onError: (err, newTodo, context: any) => {
        queryClient.setQueryData(settingQueryKey, context.previousTodo);
      },
    },
  });

  const handleFavClick = (market: string, currentFavStatus: boolean) => {
    if (!currentFavStatus) {
      updateSetting({ data: { type: 'favorite-market', value: market } });
    } else {
      deleteUserSetting({ data: { type: 'favorite-market', value: market } });
    }
  };
  if (!favoriteMarkets) return null;
  const isFav = favoriteMarkets?.has(market);
  return (
    <FavStar
      className={clsx(className, {
        [style['not-fill']]: !isFav,
      })}
      onClick={e => {
        e.stopPropagation();
        handleFavClick(market, isFav);
        if (onChange) {
          onChange();
        }
      }}
    />
  );
};
