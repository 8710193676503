import { DEFAULT_SELECTED_MARKET } from '@ping/configs';
import { parsePair } from '@ping/helpers';
import { isBrowser, Store } from '@ping/utils';

export interface IMarketStore {
  selectedMarket: string;
  selectedMarketLastPrice: string;
}

// --------------------------------------------------------------------------------
// MARKET STORE
// --------------------------------------------------------------------------------
const selectedMarketPathString = isBrowser() && new URLSearchParams(window.location.search).get('market');
const selectedMarketPath = selectedMarketPathString ? parsePair.byInstrument(selectedMarketPathString).market : null;
export const useMarketStore = new Store<IMarketStore>({
  selectedMarket: selectedMarketPath || DEFAULT_SELECTED_MARKET,
})
  .withPersist({ name: 'MARKET_STORE', enabled: !selectedMarketPath })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// MARKET STORE SELECTORS
// --------------------------------------------------------------------------------
export const selectedMarketSelector = (state: IMarketStore) => state.selectedMarket;

/**
 * It takes the selectedMarket property from the state object and returns it in lowercase with the
 * forward slash replaced with an underscore
 * @param {IMarketStore} state - IMarketStore
 */
export const selectedMarketInstrumentSelector = (state: IMarketStore) =>
  parsePair.byMarket(state.selectedMarket).instrument?.toLowerCase() || '';

/**
 * It takes the state of the selected market store and returns an object of the base and counter
 * currencies in lowercase from selected market
 * @param {IMarketStore} state - IMarketStore
 */
export const selectedMarketCurrenciesSelector = (state: IMarketStore) => {
  const { base, quote } = parsePair.byMarket(state.selectedMarket);
  return { base: base?.toLowerCase(), quote: quote?.toLowerCase() };
};

/**
 * It takes the state of the selected market store and returns the selected market with the forward
 * slash removed
 * @param {IMarketStore} state - IMarketStore
 */
export const selectedMarketReadableSelector = (state: IMarketStore) => state.selectedMarket?.replace('/', '') || '';

// --------------------------------------------------------------------------------
// MARKET STORE API
// --------------------------------------------------------------------------------
export const marketStore = {
  setSelectedMarket: (selectedMarket: string) => useMarketStore.setState({ selectedMarket }),
  getSelectedMarket: () => selectedMarketSelector(useMarketStore.getState()),
  getSelectedMarketSlug: () => selectedMarketInstrumentSelector(useMarketStore.getState()),
  getSelectedMarketReadable: () => selectedMarketReadableSelector(useMarketStore.getState()),
};
