import { HubConnectionState, ISubscription } from '@microsoft/signalr';
import { useEffect, useRef, useState } from 'react';

import { WSConnect } from './connection';
import { socketConnectivityChecker } from '@ping/helpers/socket-connectivity-checker.helper';
import { marketDataStore } from '@ping/stores/marketData.store';
import * as gtm from '@ping/helpers/gtm.helper';
import * as Sentry from '@sentry/react';

export type IMarketDataType = {
  CoinGeckoPrice: number;
  close: number;
  end: string;
  high: number;
  instrument: string;
  low: number;
  open: number;
  start: string;
  volume: number;
  oldClose: number;
};

let marketCache = new Map<string, IMarketDataType>();
let isActiveStream = false;
let connectionReconnectRegistered = false;

export const useMarketMoneyWS = (url: string, onMessage?: (message: string) => void) => {
  const [, setMarketData] = useState<Map<string, IMarketDataType>>(marketCache);
  const [isLoading, setIsLoading] = useState(true);
  const { current: connection } = useRef(WSConnect(url));
  const unsubscribe = useRef<ISubscription<never>>(null);

  const handleMiniTickerStream = (connection: any) => {
    isActiveStream = true;
    unsubscribe.current = connection.stream('MiniTicker', 3).subscribe({
      next: (market: any) => {
        const marketTemp = new Map(marketCache);
        market.forEach((data: IMarketDataType) => {
          marketTemp.set(data.instrument, {
            ...data,
            oldClose: marketTemp.get(data.instrument) ? marketTemp.get(data.instrument).close : data.close,
          });
        });
        setMarketData(marketTemp);
        marketCache = marketTemp;
        marketDataStore.setMarketData(marketTemp);
      },
      error: () => undefined,
      complete: () => {
        /* complete action */
      },
    });
  };

  useEffect(() => {
    connection.on('send', onMessage);

    connection.onclose(() => {
      gtm.webSocketDisconnectedEvent('Market');
      Sentry.captureMessage('[Websocket] Market Socket Disconnected');
      socketConnectivityChecker('Market', false);
    });

    if (!connectionReconnectRegistered && !isActiveStream) {
      connection.onreconnected(async () => {
        isActiveStream = false;
        connectionReconnectRegistered = true;
        await handleInitialConnection();
        socketConnectivityChecker('Market', connection?.state === HubConnectionState.Connected);
      });
    }

    handleInitialConnection().then(() => {
      socketConnectivityChecker('Market', connection?.state === HubConnectionState.Connected);
    });

    return () => {
      if (connection.state === HubConnectionState.Connected && unsubscribe.current) {
        unsubscribe.current.dispose();
        isActiveStream = false;
      }
    };
  }, []);

  const handleInitialConnection = async () => {
    if (connection.state === HubConnectionState.Disconnected) {
      await connection.start();
    }
    if (connection.state === HubConnectionState.Disconnecting) {
      /* For some reason onClose not works we need to check that later that's why we use setTimeout for now  */
      await new Promise(resolve => {
        /* run first time with no delay */
        const checkConnection = async () => {
          if (connection.state === HubConnectionState.Disconnected) {
            await connection.start();
          }
          connection.state === HubConnectionState.Connected ? resolve(null) : setTimeout(checkConnection, 200);
        };
        checkConnection();
      });
    }
    if (connection.state === HubConnectionState.Connected && !isActiveStream) {
      handleMiniTickerStream(connection);
    }
  };

  useEffect(() => {
    setIsLoading(connection.state !== HubConnectionState.Connected);
    socketConnectivityChecker('Market', connection?.state === HubConnectionState.Connected);
  }, [connection.state]);

  return { data: marketDataStore.getMarketData(), isLoading };
};
